define(function() {


  var editSubscriptionAddress = function() {

    const component = {};

    const _config = {
      elementEditSubscriptionAddressSubmit: '.editSubscriptionAddress_button',
      elementEditSubscriptionAddressClose: '.editSubscriptionAddressModal_close',
      elementEditSubscriptionAddressCancel: '.editSubscriptionAddressModalCancel_button',
      elementEditSubscriptionAddressConfirm: '.editSubscriptionAddressModalConfirm_button',
      elementEditSubscriptionAddressModal: '.editSubscriptionAddressModalContainer',
      elementEditSubscriptionRadioButton: '.editSubscriptionAddressDetailsNewAddressInputBox',
      elementEditSubscriptionDefaultAddressRadioButton: '.editSubscriptionAddressAddressDetailsInputBox',
      elementEditSubscriptionFieldset: '.editSubscriptionAddressBookCardFormSet',
      elementEditSubscriptionClearForm: '.editSubscriptionAddressClearForm',
      elementEditSubscriptionFormFields: '.editSubscriptionAddress_formField[type=text]',
      elementEditSubscriptionErrorMessage: '.myAccount_errorMessage',
      elementEditSubscriptionRequiredFields: 'input[required=true]',
      elementEditSubscriptionRadioInput: 'input[name=newAddress]'
    };

    const _init = function(element) {
      component.element = element;
      component.elements();
      component.noDefaultAddressShowAddressForm();
      component.bindEvents();
    };

    const _bindEvents = () => {
      component.requiredInputsFocus();
      component.modalButtonOpen();
      component.clearFormButton();
      component.requiredInputsFocus();
      component.editSubscriptionAddressCancel.addEventListener('click', () =>  component.showModal(false));
      component.editSubscriptionAddressConfirm.addEventListener('click', () => component.showModal(false));
      component.editSubscriptionAddressClose.addEventListener('click', () => component.showModal(false));

      if (component.editSubscriptionDefaultAddressRadio.length > 0) {
        component.editSubscriptionDefaultAddressRadio.forEach(item => item.addEventListener('click', () => component.isDefaultAddressSelected()));
      }

      if (component.editSubscriptionNewAddressRadio) component.editSubscriptionNewAddressRadio.addEventListener('click', () => component.enableDisableSubmit());
    };

    const _elements = () => {
      component.elementEditSubscriptionAddressModal = component.element.querySelector(component.config.elementEditSubscriptionAddressModal);
      component.editSubscriptionAddressSubmit = component.element.querySelector(component.config.elementEditSubscriptionAddressSubmit);
      component.editSubscriptionAddressCancel = component.element.querySelector(component.config.elementEditSubscriptionAddressCancel);
      component.editSubscriptionAddressConfirm = component.element.querySelector(component.config.elementEditSubscriptionAddressConfirm);
      component.editSubscriptionAddressClose = component.element.querySelector(component.config.elementEditSubscriptionAddressClose);
      component.editSubscriptionRadioButton = component.element.querySelector(component.config.elementEditSubscriptionRadioButton);
      component.editSubscriptionFieldset = component.element.querySelector(component.config.elementEditSubscriptionFieldset);
      component.editSubscriptionFields = component.element.querySelectorAll(component.config.elementEditSubscriptionFormFields);
      component.editSubscriptionNewAddressRadio = component.element.querySelector(component.config.elementEditSubscriptionRadioButton);
      component.editSubscriptionDefaultAddressRadio = component.element.querySelectorAll(component.config.elementEditSubscriptionDefaultAddressRadioButton);
      component.editSubscriptionErrorMessage = component.element.querySelector(component.config.elementEditSubscriptionErrorMessage);
      component.editSubscriptionErrorMessages = component.element.querySelector(component.config.elementEditSubscriptionErrorMessage);
      component.editSubscriptionAddressClearFormButton = component.element.querySelector(component.config.elementEditSubscriptionClearForm);
      component.editSubscriptionRequiredFields = component.element.querySelectorAll(component.config.elementEditSubscriptionRequiredFields);
      component.editSubscriptionRadios = component.element.querySelectorAll(component.config.elementEditSubscriptionRadioInput);
    };

    const _addRemoveRequiredAttributes = () => {
      if (component.editSubscriptionRadios.length < 1) {
        return false;
      }

      if (component.editSubscriptionRadios[0].checked) {
        document.querySelectorAll('[data-is-required=true]').forEach(item => item.removeAttribute('required'));
      } else {
        document.querySelectorAll('[data-is-required=true]').forEach(item => item.setAttribute('required','true'));
      }
    }

    const _modalButtonOpen = () => {
      if (component.hasEmptyRequiredFields() === true) component.editSubscriptionAddressSubmit.setAttribute('disabled', '');

      component.editSubscriptionAddressSubmit.addEventListener('click', (event) => {
        event.preventDefault();
        if (event.target.hasAttribute('disabled') !== true) component.submitFormButton();
      });
    };

    const _requiredInputsFocus = () => {
      component.editSubscriptionRequiredFields.forEach(item => {
        item.addEventListener('blur', () => component.enableDisableSubmit());
      });
    };

    const _enableDisableSubmit = () => {

      component.addRemoveRequiredAttributes();

      if (component.hasEmptyRequiredFields() !== true) {
        component.editSubscriptionAddressSubmit.removeAttribute('disabled');
      } else {
        component.editSubscriptionAddressSubmit.setAttribute('disabled','');
      }

      component.editSubscriptionFieldset.style.display = 'block';
    };

    const _submitFormButton = () => {
      component.showModal(true);
    };

    const _showModal = trueFalse => {
      component.elementEditSubscriptionAddressModal.style.display = trueFalse === true ? 'block' : 'none';
    };

    const _clearFormButton = () => {
      if (component.editSubscriptionAddressClearFormButton !== null) {
        component.editSubscriptionAddressClearFormButton.addEventListener('click', component.clearNewAddressFormValues);
      }
    };

    const _noDefaultAddressShowAddressForm = () => {
      if (component.editSubscriptionNewAddressRadio === null) {
        component.editSubscriptionFieldset.style.display = 'block';
      }
    };

    const _clearNewAddressFormValues = () => {
      component.editSubscriptionFields.forEach(item => item.value = '');
      component.editSubscriptionAddressSubmit.setAttribute('disabled', '');
    };

    const _hasEmptyRequiredFields = () => {
      return [...component.editSubscriptionRequiredFields].findIndex(item => { return item.value === '' }) > -1;
    };

    const _isDefaultAddressSelected = () => {
      if ([...component.editSubscriptionDefaultAddressRadio].findIndex(item => item.checked) > -1) {
        document.querySelectorAll('[data-is-required=true]').forEach(item => item.removeAttribute('required'));
        component.editSubscriptionAddressSubmit.removeAttribute('disabled');
        component.editSubscriptionFieldset.style.display = 'none';
      }
    };

    component.config = _config;
    component.init = _init;
    component.showModal = _showModal;
    component.modalButtonOpen = _modalButtonOpen;
    component.hasEmptyRequiredFields = _hasEmptyRequiredFields;
    component.bindEvents = _bindEvents;
    component.elements = _elements;
    component.clearFormButton = _clearFormButton;
    component.noDefaultAddressShowAddressForm = _noDefaultAddressShowAddressForm;
    component.clearNewAddressFormValues = _clearNewAddressFormValues;
    component.submitFormButton = _submitFormButton;
    component.requiredInputsFocus = _requiredInputsFocus;
    component.isDefaultAddressSelected = _isDefaultAddressSelected;
    component.enableDisableSubmit = _enableDisableSubmit;
    component.addRemoveRequiredAttributes = _addRemoveRequiredAttributes;
    return component;
  };

  return editSubscriptionAddress;

});
